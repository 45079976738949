import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { motion } from "framer-motion";
import "../Style.css";
import Spline from "@splinetool/react-spline";

const WhyMeComp = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#dddcdb",
        position: "relative",
        opacity: "1",
        backgroundImage:
          "repeating-radial-gradient( circle at 0 0, transparent 0, #ffffff 33px ), repeating-linear-gradient( #dddcdb, #dddcdb )",
      }}
    >
      <div className="whyMe-background2"></div>
      <Row
        style={{
          width: "100%",
          height: "100%",
          marginRight: "0",
          marginLeft: "0",
        }}
        lg={2}
        md={1}
        sm={1}
        xs={1}
      >
        <Col>
          <Card
            style={{
              marginTop: "10%",
              backdropFilter: "blur(4px)",
              backgroundColor: "transparent",
              borderRadius: "20px",
              borderTop: "3px solid rgb(255,255,255, 0.5)",
              borderLeft: "3px solid rgb(255,255,255, 0.5)",
              boxShadow: "0 8px 32px rgb(0, 0, 0, 0.37)",
              marginBottom: "30px",
            }}
          >
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <h1 className="whyMe-title">
                Looking to refresh <br /> your online presence?
              </h1>
              <p className="whyMe-paragraph">
                I offer both website updates and <br /> brand new custom-built
                websites.
              </p>
            </motion.div>
          </Card>

          <Spline
            scene="https://prod.spline.design/2x2XjhNlpnl8ZTCb/scene.splinecode"
            style={{ height: "350px" }}
            className="threeD-spline"
          />
        </Col>
        <Col style={{ paddingBottom: "50px" }}>
          <Card
            className="whyMe-Card"
            style={{
              marginTop: "10%",
              backdropFilter: "blur(4px)",
              backgroundColor: "transparent",
              borderRadius: "20px",
              borderTop: "3px solid rgb(255,255,255, 0.5)",
              borderLeft: "3px solid rgb(255,255,255, 0.5)",
              boxShadow: "0 8px 32px rgb(0, 0, 0, 0.37)",
            }}
          >
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              viewport={{ once: true }}
              className="whyMe-title2"
            >
              Contact me for a <br />
              Free Consultation!
            </motion.h1>
            <motion.p className="whyMe-paragraph2">
              With a focus on clean design and user experience, I can transform
              your online presence and help you reach your goals. If you have an
              existing website that needs a fresh look or updated functionality,
              I can work with you to revamp it and make it shine. Or, if you're
              starting from scratch, I can build you a website from the ground
              up that's tailored to your unique needs and vision. From
              e-commerce to blogs to portfolio sites, I have the expertise to
              create a website that represents your brand and engages your
              audience. With a commitment to quality, communication, and
              collaboration, I'm here to help you take your online presence to
              the next level.
            </motion.p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default WhyMeComp;
